.slide-container {
  position: relative;
  width: 95%;
  height: auto;
  overflow: hidden;
}
  
h3 {
  text-align: center;
}
  
.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 300px;
}
  
.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
  
.each-fade {
  display: flex;
}
  
.each-fade .image-container {
  width: 75%;
  overflow: hidden;
}
  
.each-fade .image-container img {
  width: 100%;
}
  
.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}
  
.indicator {
  width: 30px;
  color: blue;
  text-align: center;
  cursor: pointer;
  border: 1px blue solid;
}

.indicator.active {
  background: #000;
  color: #fff;
}