.flex-container {
    flex-direction: row;
    display: flex;
}
.flex-container-row {
    flex-direction: column;
    display: flex;
}
.flex-row {
    display: flex;
    flex-direction: row;
}
.flex-to-column {
    display: flex;
    flex-direction: row;
}

@media (max-width: 758px) {
    .flex-container {
        flex-direction: column;
    }
    .flex-container-row {
        flex-direction: row;
    }
    .flex-row {
        display: flex;
        flex-direction: row;
        flex: 1;
    }
    .flex-to-column {
        display: flex;
        flex-direction: column;
    }
}

.truncate-overflow {
    overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
  }