.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
  }
.editor-class {
    background-color:#eee;
    padding: 1rem;
    border: 1px solid #ccc;
}
.toolbar-class {
    border: 1px solid #ccc;
}
.preview {
    padding: 1rem;
    margin-top: 1rem;
}